<template>
  <div class="desk">
    <Search
      :search-info="searchInfo"
      :release-info="releaseInfo"
      @search="getData"
    />
    <ConditionSelection
      :options="options"
      :search-info="searchInfo"
      @search="getData"
    />
    <div v-if="officeData.length" v-loading="loading">
      <div class="container">
        <Office
          v-for="(item, index) of officeData"
          :key="item.createTime + index"
          :data="item"
          :lable-data="officeLableData"
          :detalis-text="'服务详情'"
          :path="'/service/desk/details'"
        />
        <template v-for="(item, index) in row - (len % row)">
          <div v-if="len % row > 0" :key="item + index" class="complement"></div>
        </template>
      </div>
      <Pagination class="pagination" :page-info="pageInfo" @changePage="changePage" />
    </div>

    <Empty v-else type="serviceContent" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import Office from '@/components/Service/ServiceOffice'
import ConditionSelection from '@/components/ConditionSelection'
import Search from '@/components/Service/ServiceSearch'
import { mapGetters, mapActions } from 'vuex'
import API from '@/api'
import Empty from "@/components/Empty/Factory"
export default {
  name: 'Desk',
  components: {
    ConditionSelection,
    Search,
    Pagination,
    Office,
    Empty,
  },
  data() {
    return {
      loading: true,
      row: 4,
      searchInfo: {
        country: '',
        province: '',
        keyword: '',
        type: '',
        businessTypeId: '',
        desc: '',
      },
      pageInfo: {
        currentPage: 1,
        size: 12,
        total: 120,
      },
      releaseInfo: {
        title: '3f8bf39',
        pla: '7f04dcf',
        path: '/service/desk/release',
      },
      officeData: [],
      officeLableData: [
        {
          lable: '发布时间',
          key: 'createTime',
        },
        {
          lable: '发布者',
          key: 'createBy',
        },
        {
          lable: '所属区域',
          key: 'province',
        },
        {
          lable: '涉及区域',
          key: 'country',
        },
        {
          lable: '业务类型',
          key: 'businessType',
        },
        {
          lable: '服务描述',
          key: 'content',
        },
        {
          lable: '服务量',
          key: 'num',
        },
      ],
      options: [
        {},
        {
          name: 'c2e2895',
          key: 'type',
          list: [
            {
              name: '法律服务',
              id: 'LAW',
              child: {},
            },
            {
              name: '翻译服务',
              id: 'TRANSLATE',
              child: {},
            },
            // {
            //   name: '其他服务',
            //   id: 'OTHER',
            // },
          ],
        },
        {
          name: '5237d0d',
          key: 'desc',
          list: [
            {
              name: '默认',
              id: 'default',
            },
            {
              name: '服务量',
              id: 'num',
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['lawServiceObj', 'dictionaryList', 'provinceList']),
    len() {
      return this.officeData.length
    },
  },
  watch: {
    lawServiceObj(newVal) {
      this.loading = false
      if (newVal) {
        this.officeData = newVal.content
        this.pageInfo.total = newVal.page.total
      }
    },
    dictionaryList(newVal) {
      if (newVal) {
        const list = newVal.content
        if (list[0].name === '中国') {
          list[0].child = {
            key: 'province',
            list: this.provinceList,
          }
        }
        this.options.splice(0, 1, {
          name: '70822d6',
          key: 'country',
          list: newVal.content,
        })
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeLabelData(to, from)
    })
  },
  async created() {
    this.getData()
    this.getTypeList()
    await this.getProvinceList({
      selectType: 'LAW_SERVICE'
    })
    this.getDictionaryList({
      type: 'LAW_SERVICE',
    })
  },
  methods: {
    ...mapActions(['getLawService', 'getDictionaryList', 'getProvinceList']),
    resetData() {
      this.pageInfo = {
        currentPage: 1,
        size: 12,
        total: 1,
      }
    },
    async changeLabelData(to, from) {
      if (from.path !== '/service/desk/details') {
        this.resetData()
        this.getData()
      }
    },
    getData() {
      this.loading = true
      this.getLawService({
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size,
        country: this.searchInfo.country,
        province: this.searchInfo.province,
        keyword: this.searchInfo.keyword,
        type: this.searchInfo.type,
        businessTypeId: this.searchInfo.businessTypeId,
        desc: this.searchInfo.desc,
      })
    },
    getTypeList() {
      API.common
        .getDictionaryList({
          type: 'BUSINESS_TYPE',
        })
        .then((res) => {
          const list = res.content
          this.options[1].list[0].child = {
            key: 'businessTypeId',
            list,
          }
        })
      API.common
        .getDictionaryList({
          type: 'TRANSLATION_BUSINESS_TYPE',
        })
        .then((res) => {
          const list = res.content
          this.options[1].list[1].child = {
            key: 'businessTypeId',
            list,
          }
        })
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getData()
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.desk {
  width: @uni-width;
  margin: 0 auto;
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .complement {
      width: 285px;
      height: 368px;
    }
  }
  .pagination {
    margin-bottom: 20px;
  }
}
</style>
